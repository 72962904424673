import {
  Component, SimpleChanges, OnDestroy, Input, Output, OnChanges,
  OnInit, ViewChild, EventEmitter, ViewContainerRef, ComponentRef
} from '@angular/core';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import { SubSink } from 'subsink';
import { FiltersToolbarComponent } from '../filters-toolbar/filters-toolbar.component';
import { FiltersConfig } from '../../models/filters.types';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FiltersService } from '../../services/filters.service';
import { MatMenuTrigger } from '@angular/material/menu';

export type FiltersToggleValue = Record<string, any>;

@Component({
  selector: 'agd-filters-toggle',
  templateUrl: './filters-toggle.component.html',
  styleUrls: ['./filters-toggle.component.scss'],
  animations: [AppAnimations.fadeIn],
})
export class FiltersToggleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filters: FiltersConfig;

  @Output() filterValueChange = new EventEmitter<FiltersToggleValue>();

  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  @ViewChild('filtersAnchor', { read: ViewContainerRef, static: true }) defaultAnchor: ViewContainerRef;

  amount = 0;

  displayFloatingMenu = true;

  private subs = new SubSink();

  private toolbarRefs: ComponentRef<FiltersToolbarComponent>[];

  private activeAnchors: ViewContainerRef[] = [];

  private _initialized = false;

  constructor(
    private bpObserver: BreakpointObserver,
    private filtersService: FiltersService,
  ) { }

  ngOnInit(): void {
    if (this.filters) {
      this.init(true);
    }

    const largeScreens = [Breakpoints.Large, Breakpoints.XLarge];

    // Subscribe to breakpoints changes
    this.subs.sink = this.bpObserver.observe(largeScreens).subscribe((breakpoint) => {
      // for large screens use toolbar component
      // for small screens use mobile component
      if (this.displayFloatingMenu !== !breakpoint.matches) {
        this.displayFloatingMenu = !breakpoint.matches;
        this.handleFilterComponents();
      }
    });

    // Subscribe to values updates
    this.subs.sink = this.filtersService.filtersValues.subscribe(() => {
      const values = this.filtersService.getActiveValues();

      this.amount = values ? Object.keys(values).length : 0;
      let flattenedValues = null;

      if (values) {
        flattenedValues = {};

        Object.keys(values).forEach(key => {
          const value = values[key];

          if (!Array.isArray(value) && typeof value === 'object') {
            flattenedValues = {
              ...flattenedValues,
              ...value
            };
          } else {
            flattenedValues[key] = value;
          }
        });
      }

      // prevent emitting a value due to initialization
      if (!this._initialized) {
        this._initialized = true;
        return;
      }

      this.filterValueChange.emit(flattenedValues);
    });

    // Subscribe to anchor changes
    this.subs.sink = this.filtersService.toolbarAnchors.subscribe(anchors => {
      this.activeAnchors = anchors;
      this.handleFilterComponents();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._initialized && changes.filters) {
      this.init(true);
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggle() {
    if (this.displayFloatingMenu) {
      this.matMenuTrigger.toggleMenu();
    } else {
      // toggle every toolbar instance
      this.toolbarRefs?.forEach(ref => {
        ref.instance.changeDisplay(!ref.instance._display);
      });
    }
  }

  reset() {
    this.init();
  }

  getCurrentValue() {
    return this.filtersService.getActiveValues();
  }

  private init(useInitValues = false) {
    // set the amount of filters applied
    this.filtersService.setFiltersConfig(this.filters, { useInitValues });
  }

  private handleFilterComponents() {
    if (this.displayFloatingMenu) {
      // delete toolbar components if present
      this.toolbarRefs?.forEach(anchor => anchor.destroy());
      this.toolbarRefs = null;
    } else {
      // clear previous created toolbars
      this.toolbarRefs?.forEach(anchor => anchor.destroy());
      this.toolbarRefs = [];

      const anchors = this.activeAnchors.length ? this.activeAnchors : [this.defaultAnchor];

      // adds a new toolbar components for every anchor
      anchors.forEach(anchor => {
        this.toolbarRefs.push(anchor.createComponent(FiltersToolbarComponent));
      });
    }
  }
}
