<div class="toolbar-container" [@expand]="_display ? 'expanded' : 'collapsed'">
    <div class="scroll-ctrl ctrl-prev" *ngIf="showScrollCtrls" (click)="scrollNextElem('L')">
        <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="filters" [ngClass]="{ 'p-0': !_display }" #filtersElem>
        <div class="filter" *ngFor="let filter of config | keyvalue: objOrder" (click)="focusScroll(target)" #target>
            <div class="input-group" [ngClass]="{ 'with-value': values?.[filter.key]?.displayValue }"
                [ngSwitch]="filter.value.type">
                <div *ngSwitchCase="'text'">
                    <ng-container *ngIf="!_currentValues?.[filter.key]?.value">
                        <input matInput #textValue type="text" class="form-control" [placeholder]="filter.value.label"
                            [value]="_currentValues?.[filter.key]?.value"
                            (keydown.enter)="applyFilter(filter.key, $event)">
                        <mat-icon matSuffix [ngClass]="{ 'input-lens': !textValue.value }"
                            (click)="applyFilter(filter.key, textValue.value)">
                            search
                        </mat-icon>
                    </ng-container>
                    <ng-container *ngIf="_currentValues?.[filter.key]?.value">
                        <span>{{ _currentValues[filter.key].value }}</span>
                        <mat-icon class="clear-filter-btn" *ngIf="values?.[filter.key]?.value && !filter.value.required"
                            (click)="$event.stopPropagation(); clearFilters(filter.key)">
                            close
                        </mat-icon>
                    </ng-container>
                </div>
                <div *ngSwitchCase="'number'">
                    <ng-container *ngIf="!_currentValues?.[filter.key]?.value">
                        <input matInput #numberValue onlyNumbers type="text" class="form-control"
                            [placeholder]="filter.value.label" [value]="_currentValues?.[filter.key]?.value"
                            (keydown.enter)="applyFilter(filter.key, $event)">
                        <mat-icon matSuffix [ngClass]="{ 'input-lens': !numberValue.value }"
                            (click)="applyFilter(filter.key, numberValue.value)">
                            search
                        </mat-icon>
                    </ng-container>
                    <ng-container *ngIf="_currentValues?.[filter.key]?.value">
                        <span>{{ _currentValues[filter.key].value }}</span>
                        <mat-icon class="clear-filter-btn" *ngIf="values?.[filter.key]?.value && !filter.value.required"
                            (click)="$event.stopPropagation(); clearFilters(filter.key)">
                            close
                        </mat-icon>
                    </ng-container>
                </div>
                <div *ngSwitchCase="'number-range'">
                    <button *ngIf="!_currentValues?.[filter.key]?.displayValue" mat-button
                        [matMenuTriggerFor]="numberRangeMenu" (menuOpened)="resetInput(filter.key)">
                        {{ filter.value.label }}
                    </button>
                    <div *ngIf="_currentValues?.[filter.key]?.displayValue" class="d-flex align-items-center"
                        [matMenuTriggerFor]="numberRangeMenu" (menuOpened)="resetInput(filter.key)">
                        <span>{{ _currentValues[filter.key].displayValue }}</span>
                        <mat-icon class="clear-filter-btn" *ngIf="values?.[filter.key]?.value && !filter.value.required"
                            (click)="$event.stopPropagation(); clearFilters(filter.key)">
                            close
                        </mat-icon>
                    </div>
                    <mat-menu #numberRangeMenu="matMenu" class="agd-context-menu">
                        <div class="d-flex flex-column p-3" matMenuContent (click)="$event.stopPropagation()"
                            (keydown.tab)="$event.stopPropagation()">
                            <span><b>Define un rango</b></span>
                            <mat-form-field class="mt-2">
                                <input matInput onlyNumbers type="number" inputmode="numeric"
                                    [ngModel]="getNumberRangeValue(filter.key, 'from')"
                                    (ngModelChange)="onNumberRangeChange(filter.key, $event, 'from')"
                                    placeholder="Desde">
                            </mat-form-field>
                            <mat-form-field class="mt-2">
                                <input matInput onlyNumbers type="number" inputmode="numeric"
                                    [ngModel]="getNumberRangeValue(filter.key, 'to')"
                                    (ngModelChange)="onNumberRangeChange(filter.key, $event, 'to')" placeholder="Hasta">
                            </mat-form-field>
                            <small class="text-danger mt-2"
                                *ngIf="_currentValues?.[filter.key]?.value && _currentValues?.[filter.key]?.applyDisabled">
                                El rango ingresado es inválido
                            </small>
                            <div class="d-flex align-items-center w-100 mt-2">
                                <button class="flex-grow-1 mr-2 height-40px" *ngIf="values?.[filter.key]?.value"
                                    mat-button (click)="clearFilters(filter.key)">
                                    Limpiar
                                </button>
                                <button class="flex-grow-1 height-40px" mat-raised-button color="primary"
                                    [disabled]="_currentValues?.[filter.key]?.applyDisabled"
                                    (click)="applyFilter(filter.key, _currentValues?.[filter.key]?.value)">
                                    Aplicar
                                </button>
                            </div>
                        </div>
                    </mat-menu>
                </div>
                <div *ngSwitchCase="'radio'">
                    <ng-container *ngIf="filter.value.type === 'radio'">
                        <mat-select color="primary" [hideSingleSelectionIndicator]="true"
                            [placeholder]="filter.value.label" [value]="_currentValues?.[filter.key]?.value"
                            (valueChange)="applyFilter(filter.key, $event)">
                            <mat-option *ngFor="let option of filter.value.options | async" [value]="option.value">
                                {{ option.label | titlecase }}
                            </mat-option>
                        </mat-select>
                        <mat-icon class="clear-filter-btn" *ngIf="values?.[filter.key]?.value && !filter.value.required"
                            (click)="$event.stopPropagation(); clearFilters(filter.key)">
                            close
                        </mat-icon>
                    </ng-container>
                </div>
                <div *ngSwitchCase="'select'">
                    <ng-container *ngIf="filter.value.type === 'select'">
                        <mat-select [placeholder]="filter.value.label" multiple color="primary"
                            [value]="_currentValues?.[filter.key]?.value"
                            (valueChange)="applyFilter(filter.key, $event)">
                            <mat-option *ngFor="let option of filter.value.options | async" [value]="option.value">
                                {{ option.label }}
                            </mat-option>
                        </mat-select>
                        <mat-icon class="clear-filter-btn" *ngIf="values?.[filter.key]?.value && !filter.value.required"
                            (click)="$event.stopPropagation(); clearFilters(filter.key)">
                            close
                        </mat-icon>
                    </ng-container>
                </div>
                <div *ngSwitchCase="'switch'">
                    <mat-slide-toggle labelPosition="before" color="primary"
                        [checked]="_currentValues?.[filter.key]?.value"
                        (change)="applyFilter(filter.key, $event.checked)">
                        {{ filter.value.label }}
                    </mat-slide-toggle>
                </div>
                <div *ngSwitchCase="'date-range'" (click)="rangePicker.open()">
                    <div class="ctrl-hide">
                        <ng-container *ngIf="filter.value.type === 'date-range'">
                            <mat-date-range-input [rangePicker]="rangePicker" [min]="filter.value.min"
                                [max]="filter.value.max">
                                <input matStartDate readonly [value]="_currentValues?.[filter.key]?.value?.start"
                                    (dateChange)="dateRangeStartChanged($event, filter.key)">
                                <input matEndDate readonly [value]="_currentValues?.[filter.key]?.value?.end"
                                    (dateChange)="dateRangeEndChanged($event, filter.key)">
                            </mat-date-range-input>
                        </ng-container>
                        <mat-date-range-picker panelClass="with-custom-header" #rangePicker [id]="filter.key"
                            [calendarHeaderComponent]="_dateRangeHeader" (closed)="patchDateRangeValue(filter.key)">
                            <mat-date-range-picker-actions>
                                <button class="btn-big" mat-button (click)="clearFilters(filter.key, rangePicker)"
                                    [ngClass]="{ 'invisible': !values?.[filter.key]?.value?.start }">
                                    Limpiar
                                </button>
                                <button class="btn-big" color="primary" mat-raised-button matDateRangePickerApply>
                                    Aplicar
                                </button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </div>
                    <mat-icon class="mr-2">calendar_month</mat-icon>
                    <label class="placeholder">{{ values?.[filter.key]?.displayValue || filter.value.label }}</label>
                    <mat-icon class="clear-filter-btn" *ngIf="values?.[filter.key]?.value && !filter.value.required"
                        (click)="$event.stopPropagation(); clearFilters(filter.key)">
                        close
                    </mat-icon>
                </div>
                <div *ngSwitchCase="'date'" (click)="datepicker.open()">
                    <div class="ctrl-hide">
                        <ng-container *ngIf="filter.value.type === 'date'">
                            <input matInput [matDatepicker]="datepicker" [value]="_currentValues?.[filter.key]?.value"
                                [min]="filter.value.min" [max]="filter.value.max"
                                (dateChange)="applyFilter(filter.key, $event.value)">
                        </ng-container>
                        <mat-datepicker #datepicker></mat-datepicker>
                    </div>
                    <mat-icon class="mr-2">calendar_month</mat-icon>
                    <label class="placeholder">{{ values?.[filter.key]?.displayValue || filter.value.label }}</label>
                    <mat-icon class="clear-filter-btn" *ngIf="values?.[filter.key]?.value && !filter.value.required"
                        (click)="$event.stopPropagation(); clearFilters(filter.key)">
                        close
                    </mat-icon>
                </div>
            </div>
        </div>
        <div class="clear-filters" *ngIf="clearable">
            <button mat-button (click)="clearFilters()">Limpiar filtros</button>
        </div>
    </div>
    <div class="scroll-ctrl ctrl-next" *ngIf="showScrollCtrls" (click)="scrollNextElem('R')">
        <mat-icon>chevron_right</mat-icon>
    </div>
</div>