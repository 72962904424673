import { Directive, HostListener, ElementRef } from '@angular/core';
// Directiva para impedir que se ingrese 'e' o 'E' en los inputs de solo nombre
@Directive({
  selector: '[onlyNumbers]'
})

export class onlyNumbersDirective {
  private readonly allowedPattern = /^[0-9-]+$/; // Solo dígitos y guion '-'

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const { value } = this.el.nativeElement; // Desestructurando el objeto

    this.onChange(value, event);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardValue = event.clipboardData.getData('text');
    this.isValidInput(clipboardValue, event);
  }

  private onChange(value: string, event: InputEvent | ClipboardEvent) {
    this.isValidInput(value, event);
  }

  private isValidInput(value: string, event: InputEvent | ClipboardEvent): string {
    const inputElement = this.el.nativeElement;

    // Sanitize the input by removing non-digit and non-hyphen characters
    const sanitizedValue = value.replace(/[^0-9-]/g, '');

    // If the sanitized value differs from the input, update the input element's value and prevent default action
    if (sanitizedValue !== value) {
      inputElement.value = sanitizedValue;
      event.preventDefault();
    }

    return sanitizedValue; // Return the sanitized value
  }
}
